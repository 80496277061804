
.add-cart-header {
  ion-icon {
    font-size: 20px;
  }
}

.loves-burger-toast{
  --background: #DE222A;
  --color: #fff;
}

.single-item-title{
  color: #fff;
  font-size: 26px;
  font-weight: bold;
  padding-right: 10px;
  text-shadow: 3px 1px 10px #666;
}

.single-item-price{
  text-align: right;
  color: #fff;
  font-size: 26px;
  font-weight: bold;
  text-shadow: 3px 1px 10px #666;
}

.add-cart-container {
  background-size: cover !important;
  // background: url("http://localhost/loves-burger/api/public//images/food_item/1608369677-gallery-image6-minjpg.jpg") top center no-repeat;

  .add-cart-drawer {
    padding: 28px 15px 20px 15px;
    height: 60vh;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px 30px 0 0;
    background: #fff;
    overflow: hidden;

    ion-list {
      background: #fff;
      padding: 0;
      overflow-y: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      height: 84%;

      &::-webkit-scrollbar {
        display: none;
      }

      ion-list-header {
        font-weight: 700;
        font-size: 18px;
      }

      .item-interactive {
        --highlight-background: unset;
      }

      ion-item {
        --background: #fff;
        --background-hover: none;
        box-shadow: none;

        ion-checkbox {
          margin: 10px 12px 10px 0;
          --border-color: var(--ion-color-primary);
        }

        ion-label, ion-label h4 {
          font-size: 14px;
          font-weight: 600;
        }

        ion-text {
          font-size: 12px;
        }

        .item-counter {
          margin: 0;

          ion-button {
            --background: #e0e0e0 !important;
            --box-shadow: none;
            --padding: 8px;
            height: 35px;
            width: 35px;
            --padding-start: 12px;
            --padding-end: 12px;
          }

          >ion-text {
            color: var(--ion-text-color);
            font-size: 12px;
          }
        }
      }
    }
  }
}
