.user-profile {
  .user-header-bg {
    transform: rotate(-30deg);
    margin-top: -76px;
    height: 329px;
    background: var(--ion-color-primary);
    margin-left: -400px;
    margin-bottom: 45px;
  }

  .user-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 13%;

    ion-avatar {
      width: 150px;
      height: 150px;
      box-shadow: -1px 0 11px 0 rgba(50, 50, 50, 0.44);
      margin: 0 auto 15px;
    }

    ion-label {
      h1 {
        font-weight: 600;
        font-size: 24px;
      }
    }
  }

  .user-setting-list {
    border-top: 1px solid #ececec;

    ion-icon {
      font-size: 20px;
      color: var(--ion-color-medium);
    }
  }
}