.transactions-container {
  .transactions-list {
    ion-item {
      ion-label{
        margin: 20px 0;
        text-overflow: unset;
        white-space: unset;
        overflow: unset;

        h2{
          font-weight: 600;
          margin: 0;
        }
      }

      ion-text{
        &:nth-of-type(1){
          color: var(--ion-color-medium);
          font-size: 13px;
          font-weight: 500;
        }

        &:nth-of-type(2){
          color: var(--ion-color-primary);
          font-weight: 600;
        }
      }
    }
  }
}

.order-item-price{
  color: #DE222A !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}