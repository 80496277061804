.account-container {
  ion-item-divider {
    border-bottom: 1px solid #ececec !important;

    ion-label {
      h1 {
        font-size: 20px;
        color: var(--ion-text-color, #000);
        font-weight: 600;
      }
    }
  }


  ion-item {
    ion-icon{
      --margin-end:20px;
    }

    ion-label {
      p {
        color: var(--ion-color-medium);
        font-weight: 600;
        font-size: 16px;
      }
    }

    ion-toggle{
      padding-right: 8px;
    }
  }
}