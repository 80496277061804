ion-tab-bar{
  box-shadow: 0 6px 12px 0 rgba(0 ,0 , 0, 0.41);

  ion-icon{
    font-size: 22px;
  }
}

.notification-button {            
  position: relative;
  width: 42px;
  top:1px;
  right: 1px;
  overflow: visible!important;
}


.notifications-badge {
  background-color: #DE222A;
  position: absolute;
  top: 18px;
  right: 19px;
  border-radius: 100%;
}