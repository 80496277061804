.menu-item,.home-slider{
  h4 {
    color: #000;
    font-weight: bold!important;
    font-size: 18px!important;
    margin-bottom: 20px!important;
  }

  ion-card {
    width: 100%;
    border-radius: 10px;
    margin: 0 10px 0 0;
    height: 100%;

    ion-badge {
      position: absolute;
      top: 20px;
      left: 20px;
      padding: 5px 10px;
      font-weight: 400;
    }

    ion-text{
      position: absolute;
      bottom: 17px;
      left: 17px;
      font-weight: 600;
      color: #fff;
      letter-spacing: 1px;
    }

    ion-img {
      object-fit: cover !important;
      height: 100%;
      &:after{
        content: "";
        position: absolute;
        width: 100%;
        height:100%;
        top:0;
        left:0;
        background:rgba(0,0,0,0.4);
        //background: linear-gradient(0deg, rgba(48, 48, 48, 0.3), rgba(128, 128, 128, 0.3))
      }
    }
  }
}

.home-slider {
  padding: 40px 15px 0;

  ion-slide {
    width: 185px !important;
    height: 200px;
  }
}