.about-us {
    padding: 30px 15px;
    .about-detail {
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
      border-bottom: 1px solid #e1e1e1;
  
  
      ion-img {
        margin-bottom: 30px;
      }
  
      h6{
        margin-bottom: 20px!important;
      }
    }
    p{
      font-weight: 600;
    }
    .input-group{
        display: flex;
        align-items: center !important;
        margin-bottom: 15px;
    }
    .item-input{
        --padding-start: 0;
        --border-color: #737373;
        --min-height: 44px;
        width: 100%;
    }
    .ico{
        --ionicon-stroke-width: 40px;
        font-size: 30px;
        margin-right: 15px;
    }

    .bottom-btn{
        float: right !important;
    }
}