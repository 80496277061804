.home-page {
  .home-header {
    ion-icon {
      padding: 6px 8px;
      margin-right: 10px;
      font-size: 20px;
      --ionicon-stroke-width: 46px;
      color: #fff;
    }

    .badge{
      position: relative;

      &:before{
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        right: 5px;
        top: 4px;
        background: var(--ion-color-primary);
      }
    }

    

    ion-toolbar {
      --background: transparent;
    }
  }

  ion-content {
    position: absolute;
    top: 0;
    left: 0;
  }

  .home-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    min-height: 51%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0.47)), url('../../assets/images/banner-4.jpg') no-repeat center left;
    background-size: cover;
    color: #fff;
    border-bottom-left-radius: 45%;

    h1 {
      font-size: 32px;
      color: #fff;
      font-weight: bold;
      margin-left: 45px;
    }

    .banner-content {
      margin-left: 20px;

      h6 {
        font-size: 14px;
        margin-bottom: 8px !important;
        font-weight: 600;
      }
    }


    ion-button {
      position: absolute;
      bottom: -20px;
      right: 0;
      margin: 0;
      width: 55%;
      height: 45px;
      --box-shadow: none;
      --border-radius: 64px 0 0 64px;
    }
  }
}



