.payment-container {
  --padding-top:30px;

  ion-list {
    // padding: 30px 0 100px;

    ion-list-header {
      ion-label {
        color: var(--ion-text-color);
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
      }
    }

    ion-item{
      --inner-border-width: 0;
      border-bottom: 1px solid #ececec;

      &:last-of-type{
        border-bottom: none;
      }

      ion-radio{
        margin: 0;
      }

      ion-label{
        font-weight: bold;
        margin: 25px 0;
      }

      ion-img{
        width: 30px;
      }
    }
  }

  ion-button{
    margin: 0;
    --border-radius: 2px;
  }
}

.order-alert{
  text-align: center;

  img{
    width: 60px;
    height: 60px;
  }

  p{
    font-weight: 600;
    color: #000000;
    margin: 8px 0;
  }
}