.container.contact-us {
  .contact-detail {
    width: 100%;
    max-width: 220px;
    margin: 0 auto;

    ion-img {
      width: 205px !important;
      height: 205px !important;
    }

    h4 {
      font-weight: 600 !important;
      font-size: 18px !important;
      margin-bottom: 20px !important;
    }

    p {
      font-weight: 600;
      margin-bottom: 30px;
    }
  }

  ion-button {
    margin: 0 20px;
    --border-radius: 2px;
  }
}

.callus-btn{
  background-color: #DE222A;
  color: #fff !important;
  padding: 5px 30px;
  border-radius: 3px;
}

.call-us-alert{
  .alert-button,.alert-message{
   color: var(--ion-text-color);
  }
}