.menu-slider {
 margin: 20px 0;

  ion-icon {
    padding: 6px 8px;
    margin-right: 10px;
    font-size: 20px;
    --ionicon-stroke-width: 46px;
    color: #fff;
  }

  ion-slide {
    width: auto !important;

    ion-button {
      width: 100%;
    }
  }
}