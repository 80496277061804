
.menu-header{
  ion-icon{
    font-size: 20px;
  }

  ion-icon:not(ion-icon:nth-of-type(1)) {
    padding: 6px 8px;
    margin-right: 10px;
    font-size: 20px;
    --ionicon-stroke-width: 46px;
    color: #fff;
  }
}

ion-content.menu-container {
  .menu-items-container{
    padding: 0 20px;
    ion-card{
      margin-bottom: 20px;
    }
  }
}