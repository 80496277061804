

ion-content{
  position: relative;
}

.cart-section {
  .cart-header {
    width: 100%;
    max-width: 165px;

    h1 {
      font-size: 36px;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 6px;
    }

    h2 {
      font-size: 32px;
      font-weight: bold;
      letter-spacing: 1px;
      margin-top: 0;
      color: var(--ion-color-primary);
    }
  }

  .cart-container {
    .cart-row {
      .cart-item {
        padding: 20px 0 20px 40px;
        border-left: 2px solid var(--ion-color-primary);
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: -7px;
          top: 45px;
          width: 12px;
          height: 12px;
          background-color: var(--ion-color-primary);
          border-radius: 50%;
        }

        h3{
          font-weight: 400;
          font-size: 18px;
        }

        .item-quantity{
          font-size: 12px;
        }
        .item-price{
          font-size: 12px;
        }
      }
    }
  }
}
.btn-check{
  position: fixed;
  bottom: 14px;
  right: 20px;

  ion-icon{
    --ionicon-stroke-width: 46px;
  }
}