.header-center{
  ion-title{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
  }
}

.side-avatar-list{
  ion-item{
    --inner-border-width: 0;
    border-bottom: 1px solid #ececec;
    &:last-of-type{
      border-bottom: none;
    }

    ion-label{
      h2{
        font-weight: 500;
        margin-bottom: 6px!important;
      }

      p{
        font-style: italic;
      }
    }
  }
}

.search-container{
  h1{
    margin-bottom: 30px;
    margin-top: 20px!important;
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 1px;
  }
  ion-searchbar{
    padding: 0;
    --box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.14), 0 0px 1px 0px rgba(0, 0, 0, 0.2), 0 1px 22px 0 rgba(0, 0, 0, 0.12);
    --border-radius: 8px;
    margin-bottom: 20px;
    ion-icon{
      color: var(--ion-color-primary) !important;
      left: 18px!important;
      top: 14px!important;
    }

    .searchbar-input{
      padding: 10px 20px 8px 50px;
    }
  }

  .side-avatar-list{
    ion-item{
      border-bottom: none;
    }
  }
}