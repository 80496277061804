.cart-confirm-container {
  --background: url("../../assets/images/banner-1.jpg") bottom center no-repeat;
  .cart-confirm {
    background: var(--ion-color-primary);
    padding: 30px 20px;
    border-radius: 0 0 20px 20px;

    h1 {
      margin: 0;
      color: #fff;
      font-weight: bold;
      font-size: 30px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    ion-text {
      color: #fff;
      font-weight: 600;
    }
  }
}