.about-us {
  padding: 30px 0;
  .about-detail {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    border-bottom: 1px solid #e1e1e1;


    ion-img {
      margin-bottom: 30px;
    }

    h6{
      margin-bottom: 20px!important;
    }
  }
  p{
    font-weight: 600;
  }
}