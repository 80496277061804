
h1, h2, h3, h4, h5, h6 {
  margin-top: 0 !important;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container .icon-img {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
}

ion-header {
  ion-icon:first-of-type {
    font-size: 22px;
  }
}

.custom-list {
  ion-item {
    --inner-border-width: 0;
    border-bottom: 1px solid #ececec;

    &:last-of-type {
      border-bottom: none;
    }

    ion-label {
      margin: 16px 0;

      h2 {
        font-weight: 600;
        margin: 0;
      }
    }
  }
}

//Add Subtract Counter Component style

.item-counter {
  display: flex;
  margin-top: 8px;

  ion-button {
    width: 40px;
    height: 40px;
    color: var(--ion-text-color);
    --background: #fff;
    --border-radius: 3px;
  }

  ion-text {
    color: #fff;
    display: flex;
    margin: 0 14px;
  }
}

.btn-default{
  margin: 0 35px;
  --border-radius: 2px;
  position: fixed;
  bottom: 28px;
  left: 0;
  right: 0;
}