a {
  text-decoration: none;
  color: #000 !important;
}

.login-container{
  scroll-behavior: smooth;
}

.back-to-social{
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 600;
}

.back-to-social ion-icon{
  margin-right: 7px;
  font-size: 20px;
  --ionicon-stroke-width: 25px;
}

.signin-btn-group p{
  color: #ffff;
  font-weight: bold;
}

.loves-burger-toast{
  --background: #DE222A;
  --color: #fff;
}

.load-spinner{
  position: absolute;
  top: 65%;
  left: 0;
  right: 0;
  margin: auto;
}

.login-container {
  .login-banner {
    position: relative;
    background: linear-gradient(0deg, rgba(48, 48, 48, 0.4), rgba(128, 128, 128, 0.4)), url('../../assets/images/Chicken-Burgers.jpg') no-repeat center left;
    background-size: cover;
    min-height: 65%;
    margin: 0 4px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    padding: 30px 0;
    --color: #fff;

    h1 {
      font-size: 36px;
      color: #fff;
      margin: 15px 0 0;
      font-weight: bold;
    }

    .banner-bottom {
      position: absolute;
      bottom: 12%;
      padding: 0 27px;

      .banner-quote {
        width: 100%;

        p, span {
          font-style: italic;
          color: #fff;
          font-weight: 600;
        }
      }

      .signin-btn-group {
        display: flex;
        justify-content: end;

        a {
          color: #fff !important;
          font-weight: 600;
          &:active{
            text-decoration: underline;
          }
        }

        .sign-in-btn {
          margin-right: 20px;
        }
      }
    }
  }

  .social-login-wrapper {
    padding-top: 30px;

    h4 {
      color: var(--ion-text-color);
      font-weight: bold!important;
      font-size: 18px!important;
      margin-bottom: 20px!important;
    }

    ul {
      list-style: none;
      display: flex;
      padding: 0;
      max-width: 254px;
      margin: 0 auto;

      li {
        width: 45px;
        height: 45px;
        display: inline-block;
        float: left;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
          border: 2px solid black;
          width: 89px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 25px;

          a {
            text-decoration: none;
            color: #000;
            font-weight: bold;
          }
        }
      }
    }
  }

  .login-signup-form {
    padding: 80px 30px;

    .input-group {
      display: flex;

      ion-icon {
        --ionicon-stroke-width: 40px;
        font-size: 30px;
        margin-right: 15px;
      }

      .item-input {
        --padding-start: 0;
        --border-color: #737373;
        --min-height: 44px;
        width: 100%;
        //margin-left: 40px;
      }
    }

    .btn-group {
      margin-top: 30px;
      display: flex;

      a {
        display: flex;
        align-items: center;
        color: #000;
        font-weight: 600;
        width: 115px;

        ion-icon {
          margin-right: 7px;
          font-size: 20px;
          --ionicon-stroke-width: 25px;
        }
      }
    }
  }
}